<template>
  <div class="text-sm space-y-4 mt-4">
    <p>
      Die Videodatei wird ohne Upload im Browser konvertiert. Der Computer kann während der
      Konvertierung langsamer reagieren und die Lüfter können hörbar sein.
    </p>
    <p>
      <strong>Unterstützte Formate:</strong><br />
      MP4, WebM, MOV
    </p>
    <p>
      <strong>Maximale Breite und Höhe:</strong><br />
      1920&times;1080&ensp;/&ensp;1440&times;1440&ensp;/&ensp;1080&times;1920
    </p>
    <p>
      <strong>Qualität vs Dateigröße:</strong><br />
      Schieberegler nur anpassen, wenn das konvertierte Video viele Artefakte aufweist.
    </p>
  </div>
</template>
