import './assets/index.css';

import { createApp } from 'vue';
import App from './App.vue';
import * as Sentry from '@sentry/vue';

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://cdfce11cca9b5d1306b6b5178b003a5e@o4507576553570304.ingest.de.sentry.io/4507576610717776',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: 'system',
      showBranding: false,
      triggerLabel: 'Problem melden',
      triggerAriaLabel: 'Problem melden',
      formTitle: 'Problem melden',
      submitButtonLabel: 'Senden',
      cancelButtonLabel: 'Abbrechen',
      confirmButtonLabel: 'Bestätigen',
      addScreenshotButtonLabel: 'Screenshot hinzufügen',
      removeScreenshotButtonLabel: 'Screenshot entfernen',
      nameLabel: 'Name',
      namePlaceholder: 'Dein Name',
      emailLabel: 'E-Mail',
      emailPlaceholder: 'Deine E-Mail',
      messageLabel: 'Beschreibung',
      messagePlaceholder: 'Welches Problem hast du?',
      isRequiredLabel: '(erforderlich)',
      successMessageText: 'Danke für deine Nachricht!'
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount('#app');
