<script lang="ts" setup>
import { ref } from 'vue';

defineProps<{
  disabled?: boolean;
}>();

const emit = defineEmits(['change']);

const fileInput = ref<HTMLInputElement | null>(null);
const file = ref<File | null>(null);
const isDragOver = ref(false);
const errorMessage = ref('');

// Add validation function
function isValidVideoFile(file: File): boolean {
  return file.type.startsWith('video/');
}

function handleDrop(e: DragEvent) {
  const files = e.dataTransfer?.files;
  if (!fileInput.value || !files || files.length !== 1) return;

  const droppedFile = files[0];

  // Validate file type
  if (!isValidVideoFile(droppedFile)) {
    errorMessage.value = 'Bitte nur Videodateien auswählen';
    isDragOver.value = false;
    return;
  }

  file.value = droppedFile;
  fileInput.value.files = files;
  isDragOver.value = false;
  errorMessage.value = '';

  emit('change', { target: fileInput.value });
}

function handleFileChange(e: Event) {
  const target = e.target as HTMLInputElement;

  if (target.files?.length === 1) {
    const selectedFile = target.files[0];

    // Validate file type
    if (!isValidVideoFile(selectedFile)) {
      errorMessage.value = 'Bitte nur Videodateien auswählen';
      file.value = null;
      target.value = ''; // Reset input
      return;
    }

    file.value = selectedFile;
    errorMessage.value = '';
  } else {
    file.value = null;
    errorMessage.value = '';
  }

  emit('change', { target: fileInput.value });
}
</script>

<template>
  <div class="relative">
    <div
      class="flex flex-col items-center justify-center h-20 relative z-10 rounded-md border border-input bg-transparent p-3 text-sm shadow-sm cursor-pointer transition-colors"
      :class="{
        '!border-gray-700': isDragOver,
        'text-gray-500': !file,
        'pointer-events-none cursor-not-allowed opacity-60': disabled,
        '!border-red-500': errorMessage
      }"
      @click="($refs.fileInput as HTMLInputElement).click()"
      @dragover.prevent="isDragOver = true"
      @dragleave="isDragOver = false"
      @dragend="isDragOver = false"
      @drop.prevent="handleDrop"
    >
      <p class="pointer-events-none text-center break-words max-w-full">
        <template v-if="!file"> Datei auswählen oder <br />über Drag & Drop hier ablegen </template>
        <template v-else> {{ file?.name }} </template>
      </p>
      <p v-if="errorMessage" class="text-red-500 text-xs mt-1">{{ errorMessage }}</p>
    </div>
    <input
      class="absolute top-0 left-0 invisible"
      type="file"
      ref="fileInput"
      @change="handleFileChange"
      accept="video/*"
    />
  </div>
</template>
